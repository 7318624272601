<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <p>{{ $t("recette.mois") }}</p>
        <a-select
          id="selectMonth"
          show-search
          :placeholder="$t('recette.tousMois')"
          option-filter-prop="children"
          style="width: 200px; margin-bottom: 20px"
          :filter-option="filterOption"
          @change="handleChangeSelectMois"
        >
          <a-select-option value="all">
            {{ $t("recette.tousMois") }}
          </a-select-option>
          <a-select-option
            v-for="(m, index) in monthsArr"
            :key="index + 1"
            :value="index + 1"
          >
            {{ m }}
          </a-select-option>
        </a-select>
        <a-dropdown
          :disabled="pdfLoading"
          style="margin-bottom: 20px; float: right"
        >
          <a-menu slot="overlay">
            <a-menu-item key="3" @click="generatePdf">
              <a-icon type="file-pdf" /> {{ $t("action.imprimerTab") }}
            </a-menu-item>
            <a-menu-item key="1" @click="generatePdfScolarite">
              <i class="fa fa-graduation-cap"></i>
              {{ $t("menu.etatImpayes") }} - {{ $t("menu.scolarite") }}
            </a-menu-item>
            <a-menu-item key="2" @click="generatePdfActivites">
              <i class="fa fa-tags"></i> {{ $t("menu.etatImpayes") }} -
              {{ $t("menu.activites") }}
            </a-menu-item>
          </a-menu>
          <a-button
            :loading="pdfLoading"
            :disabled="pdfLoading"
            icon="file-pdf"
          >
            {{ $t("action.imprimer") }}
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
        <json-csv
          class="ant-btn"
          style="margin-bottom: 20px; margin-right: 20px; float: right"
          :fields="fields"
          :labels="labels"
          :data="csvExportTable"
          :name="'etatDesImapyés.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
      </div>
      <div>
        <a-table
          @change="tableChanged"
          rowKey="_id"
          :loading="loadingData"
          :pagination="true"
          :data-source="data"
          :columns="columns"
        >
          <template
            slot="expandedRowRender"
            slot-scope="record"
            v-if="showThisRow(record)"
          >
            <table class="table table-striped" v-if="record.type == 'activity'">
              <thead>
                <th>{{ $t("recette.mois") }}</th>
                <th>{{ $t("recette.montantRestant") }}</th>
              </thead>
              <tbody>
                <tr v-if="!record.inscription && mF">
                  <td>{{ $t("recette.inscription") }}</td>
                  <td>
                    <del v-if="record.reduction.inscription > 0">
                      {{ record.activity.inscription }}
                    </del>
                    <div v-else>{{ record.activity.inscription }}</div>
                    <sup
                      v-if="record.reduction.inscription > 0"
                      class="text-success"
                      >-{{ record.reduction.inscription }}%</sup
                    >
                    <div v-if="record.reduction.inscription > 0">
                      ={{
                        record.activity.inscription -
                        (record.activity.inscription *
                          record.reduction.inscription) /
                          100
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-for="(month, key) in record.months" :key="key">
                  <td>{{ getMonthName(key) }} ({{ key }})</td>
                  <td>
                    <div v-if="!record.months[key]">
                      {{ record.activity.monthly }}
                      <sup
                        v-if="record.reduction.monthly > 0"
                        class="text-success"
                        >-{{ record.reduction.monthly }}%</sup
                      >
                      <div v-if="record.reduction.monthly > 0">
                        ={{
                          record.activity.monthly -
                          (record.activity.monthly * record.reduction.monthly) /
                            100
                        }}
                      </div>
                    </div>
                    <div v-else>0</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-striped" v-else>
              <thead>
                <th>{{ $t("recette.mois") }}</th>
                <th>{{ $t("recette.montantRestant") }}</th>
              </thead>
              <tbody>
                <tr v-if="!record.inscription && mF">
                  <td>{{ $t("recette.inscription") }}</td>
                  <td>
                    <del v-if="record.reduction.inscription > 0">
                      {{ record.classRoom.inscription }}
                    </del>
                    <div v-else>{{ record.classRoom.inscription }}</div>
                    <sup
                      v-if="record.reduction.inscription > 0"
                      class="text-success"
                      >-{{ record.reduction.inscription }}%</sup
                    >
                    <div v-if="record.reduction.inscription > 0">
                      ={{
                        record.classRoom.inscription -
                        (record.classRoom.inscription *
                          record.reduction.inscription) /
                          100
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-for="(month, key) in record.months" :key="key">
                  <td>{{ getMonthName(key) }} ({{ key }})</td>
                  <td>
                    <div v-if="!record.months[key]">
                      {{ record.classRoom.monthly }}
                      <sup
                        v-if="record.reduction.monthly > 0"
                        class="text-success"
                        >-{{ record.reduction.monthly }}%</sup
                      >
                      <div v-if="record.reduction.monthly > 0">
                        ={{
                          record.classRoom.monthly -
                          (record.classRoom.monthly *
                            record.reduction.monthly) /
                            100
                        }}
                      </div>
                    </div>
                    <div v-else>0</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template slot="parent" slot-scope="text, record">
            {{ record.parent.father.firstName }}
            {{ record.parent.father.lastName }}<br />
            {{ record.parent.mother.firstName }}
            {{ record.parent.mother.lastName }}
          </template>
          <template slot="phone" slot-scope="text, record">
            {{ record.parent.father.phone }}<br />
            {{ record.parent.mother.phone }}
          </template>
          <template slot="student" slot-scope="text, record">
            {{ record.student.firstName }} {{ record.student.lastName }}
          </template>
          <template slot="type" slot-scope="text, record">
            <div>
              {{ text == "activity" ? "Activité: " : "Scolarité: "
              }}{{ record.name }}
            </div>
          </template>
          <!--
      <template
        v-for="col in [
          'nomClient',
          'telephone',
          'enfant',
          'frais',
          'montant',
        ]"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record.key, col)"
          />
          <template v-else>{{ text }}</template>
        </div>
      </template>
      -->
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              :title="$t('all.sureToDelete')"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.supprimer")
                }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
import _ from "lodash";
import moment from "moment";

const getStatus = (entry) => {
  if (!entry.inscription) return "impayed";
  else {
    if (entry.once == false && entry.months) {
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    }
    return "payed";
  }
};

const activityFormater = (activityFees) => {
  return {
    _id: activityFees._id,
    name: activityFees.activity[0].designation,
    once: activityFees.activity[0].once,
    inscription: activityFees.inscription,
    months: activityFees.months,
    reduction: activityFees.reduction,
    activity: activityFees.activity[0],
    service: activityFees.service[0],
    student: activityFees.student[0],
    parent: activityFees.parent[0],
    type: "activity",
  };
};

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  console.log(monthsArr);
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};

export default {
  components: {
    JsonCsv,
  },
  async created() {
    this.monthsArr = moment.months();
    this.loadingData = true;
    apiClient
      .post("/students/activity/payment/filter", {
        query: {
          status: { $ne: "inactive" },
        },
        aggregation: [
          {
            $lookup: {
              from: "activities",
              localField: "activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "services",
              localField: "activity.service",
              foreignField: "_id",
              as: "service",
            },
          },
          {
            $project: {
              inscription: 1,
              reduction: 1,
              months: 1,
              service: { name: 1 },
              student: {
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              activity: {
                _id: 1,
                once: 1,
                inscription: 1,
                monthly: 1,
                start: 1,
                end: 1,
                designation: 1,
                order: 1,
              },
            },
          },
        ],
      })
      .then((res) => {
        let dataList = res.data.map((elem) => activityFormater(elem));
        dataList.forEach((elem) => {
          const status = getStatus(elem);
          if (status != "payed") {
            this.data.push({
              ...elem,
              status: status,
            });
          }
          this.rowData.push({
            ...elem,
            status: status,
          });
        });
      });
    await apiClient
      .post("/classrooms/payment/filter", {
        query: {
          status: { $ne: "inactive" },
        },
        aggregation: [
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classroom",
            },
          },
          {
            $project: {
              _id: 1,
              months: 1,
              student: {
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              inscription: 1,
              reduction: 1,
              classroom: {
                _id: 1,
                name: 1,
                inscription: 1,
                monthly: 1,
              },
            },
          },
        ],
      })
      .then((res) => {
        let dataList = res.data.map((elem) => ({
          _id: elem._id,
          name: elem.classroom[0].name,
          classRoom: elem.classroom[0],
          inscription: elem.inscription,
          reduction: elem.reduction,
          months: elem.months,
          student: elem.student[0],
          parent: elem.parent[0],
          once: false,
          type: "scolarity",
        }));
        dataList.forEach((elem) => {
          const status = getStatus(elem);
          if (status !== "payed")
            this.data.push({
              ...elem,
              status: status,
            });
          this.rowData.push({
            ...elem,
            status: status,
          });
        });
      });
    this.loadingData = false;

    this.cacheData = this.data.map((item) => ({ ...item }));
    this.filtredTable = this.data;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];
    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " - " + start;
  },
  computed: {
    ...mapState(["settings", "user"]),
    csvExportTable() {
      const modifiedData = [...this.data];
      return modifiedData.map((item) => {
        let sum = 0;
        _.forEach(item.months, (value, key) => {
          value
            ? (sum += 0)
            : item.type == "activity"
            ? (sum += item.activity.monthly)
            : (sum += item.classRoom.monthly);
        });
        if (item.parent && item.student)
          return {
            nomClient: `${item.parent.father.firstName} ${item.parent.father.lastName} ${item.parent.mother.firstName} ${item.parent.mother.lastName}`,
            telephone: `Pére : ${item.parent.father.phone} mére: ${item.parent.mother.phone}`,
            enfant: `${item.student.firstName} ${item.student.lastName}`,

            type:
              (item.type == "activity" ? "Activité: " : "Scolarité: ") +
              item.name,
            montantImpayé: sum,
          };
      });
    },
  },
  data() {
    return {
      pdfLoading: false,
      monthsArr: [],
      labels: {
        nomclient: "Nom client",
        telephone: "Telephone",
      },
      fields: ["nomClient", "telephone", "enfant", "type", "montantImpayé"],
      mF: true,
      rowData: [],
      editingKey: "",
      students: [],
      visibleParentFile: false,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      value: 1,
      loadingData: false,
      visibleData: false,
      indeterminate: true,
      checkAll: false,
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "parent",
          key: "parent",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "parent",
          },
          onFilter: (value, record) => {
            let clientName =
              record.parent.father.firstName +
              " " +
              record.parent.father.lastName +
              " " +
              record.parent.mother.firstName +
              " " +
              record.parent.mother.lastName;
            return clientName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },

        {
          title: this.$t("recette.telephone"),
          dataIndex: "parent",
          key: "phone",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "phone",
          },
          onFilter: (value, record) =>
            (record.parent.father.phone + " " + record.parent.mother.phone)
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.enfant"),
          dataIndex: "student",
          key: "student",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "student",
          },
          onFilter: (value, record) => {
            let studentName =
              record.student.firstName + " " + record.student.lastName;
            return studentName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Frais",
          dataIndex: "type",
          key: "type",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) => {
            const getType = (typeR, r) => {
              return (
                (typeR == "activity" ? "Activité: " : "Scolarité: ") + r.name
              );
            };
            return getType(record.type, record)
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    moment,
    getMonthName,
    showThisRow(record) {
      if (!record.inscription) return true;
      else {
        for (const key in record.months) {
          if (!record.months[key]) return true;
        }
        return false;
      }
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    getStudent(id) {
      try {
        return this.students.filter((s) => s._id == id)[0];
      } catch {
        return null;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    async generatePdf() {
      this.$gtag.event("Imp Etat des impayés", {
        event_category: "Impression PDF",
        event_label: "comptabilite:Etat des impayés",
        value: 1,
      });

      let selectMonth = document.getElementById("selectMonth").innerText;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      this.pdfLoading = true;
      await new Promise((resolve) => setTimeout(resolve, 200));

      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let monthNumber = date.getMonth();

      var titre = "Etat des impayés";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setLanguage("ar");
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(89, 70, selectMonth);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      var somme = 0;

      for (let i = 0; i < this.filtredTable.length; i++) {
        let inscriptionFees = 0;
        if (this.filtredTable[i].inscription === false) {
          if (this.filtredTable[i].type == "scolarity") {
            inscriptionFees = this.filtredTable[i].classRoom.inscription;
          } else {
            inscriptionFees = this.filtredTable[i].activity.inscription;
          }
        }

        let currentIndex = 0;
        let monthsUnpaied = 0;
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            if (this.filtredTable[i].months[key] === false) monthsUnpaied++;
          }
        }
        if (currentIndex === 0) {
          currentIndex++;

          if (
            this.filtredTable[i].inscription === false &&
            inscriptionFees !== 0
          )
            monthsUnpaied++;
          if (monthsUnpaied !== 0) {
            arr.unshift({
              rowSpan: monthsUnpaied,
              content:
                this.filtredTable[i].student.firstName +
                " " +
                this.filtredTable[i].student.lastName,
              styles: { valign: "middle" },
            });
            arr.unshift({
              rowSpan: monthsUnpaied,
              content: this.filtredTable[i].parent.father.phone,
              styles: { valign: "middle" },
            });
            arr.unshift({
              rowSpan: monthsUnpaied,
              content:
                this.filtredTable[i].parent.father.firstName +
                " " +
                this.filtredTable[i].parent.father.lastName,
              styles: { valign: "middle" },
            });
          }
        }
        if (
          this.filtredTable[i].inscription === false &&
          inscriptionFees !== 0
        ) {
          /*if (!currentIndex) {
            arr.push(
              this.filtredTable[i].parent.father.firstName +
                " " +
                this.filtredTable[i].parent.father.lastName
            );
            arr.push(this.filtredTable[i].parent.father.phone);
            arr.push(
              this.filtredTable[i].student.firstName +
                " " +
                this.filtredTable[i].student.lastName
            );
          }*/
          arr.push("Inscription " + this.filtredTable[i].name);
          if (this.filtredTable[i].type == "scolarity") {
            arr.push(
              this.filtredTable[i].classRoom.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
            somme += parseFloat(
              this.filtredTable[i].classRoom.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
          } else {
            arr.push(
              this.filtredTable[i].activity.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
            somme += parseFloat(
              this.filtredTable[i].activity.inscription *
                (1 - this.filtredTable[i].reduction.inscription / 100)
            );
          }

          array.push(arr);
          arr = [];
        }
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            let paymentDate = new Date(key);
            let monthName = paymentDate.toLocaleString("fr-FR", {
              month: "long",
            });
            if (this.filtredTable[i].months[key] === false) {
              // arr.push(this.filtredTable[i].student.firstName + ' ' + this.filtredTable[i].student.lastName);
              arr.push(monthName + " - " + this.filtredTable[i].name);
              if (this.filtredTable[i].type == "scolarity") {
                arr.push(
                  this.filtredTable[i].classRoom.monthly *
                    (1 - this.filtredTable[i].reduction.monthly / 100)
                );
                somme += parseFloat(
                  this.filtredTable[i].classRoom.monthly *
                    (1 - this.filtredTable[i].reduction.monthly / 100)
                );
              } else {
                arr.push(
                  this.filtredTable[i].activity.monthly *
                    (1 - this.filtredTable[i].reduction.monthly / 100)
                );
                somme += parseFloat(
                  this.filtredTable[i].activity.monthly *
                    (1 - this.filtredTable[i].reduction.monthly / 100)
                );
              }
              //colspan
              array.push(arr);

              arr = [];
            }
          }
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Nom client", "Téléphone", "Enfant", "Frais", "Montant"]],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 3,
              content:
                writtenNumber(parseFloat(somme).toFixed(2), { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
            parseFloat(somme).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering

      this.pdfLoading = false;
      doc.save("Etat_des_impayés.pdf");
    },

    formatMontant(montant) {
      if (!Number(montant)) return false;
      return parseFloat(montant).toFixed(3);
    },

    async generatePdfScolarite() {
      this.pdfLoading = true;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      var impayeData;

      await apiClient
        .get("/comptabilite/etatImpaye/scolarite")
        .then((res) => (impayeData = res.data))
        .catch((e) => {
          console.error(e);
          this.$message.error(this.$t("error.error"));
        });

      const levels = {
        "-2": "Préscolaire",
        "-1": "تحضيري",
        1: "سنة أولى",
        2: "سنة ثانية",
        3: "سنة ثالثة",
        4: "سنة رابعة",
        5: "سنة خامسة",
        6: "سنة سادسة",
        7: "سنة سابعة",
        8: "سنة ثامنة",
        9: "سنة تاسعة",
        10: "أولى ثانوي",
        11: "ثانية ثانوي",
        12: "ثالثة ثانوي",
        13: "رابعة ثاتوي",
      };

      const doc = new JsPDF({
        orientation: "p",
        unit: "mm",
        format: "a3",
      });

      const pdf_width = doc.internal.pageSize.getWidth();

      const cellToDelete = {};

      for (const level of impayeData.levels) {
        if (level.classrooms.length === 0) continue;

        try {
          doc.addImage(imgData, "JPEG", 10, 8, 20, 20);
        } catch (error) {
          console.log(error);
        }

        doc.setFont("Amiri", "normal");
        doc.setFontSize(10);

        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };

        doc.text(
          new Date().toLocaleDateString("fr-FR", options),
          pdf_width - 17,
          8,
          {
            align: "right",
          }
        );

        doc.text(this.schoolarYearName, pdf_width / 2, 8, {
          align: "center",
        });

        doc.setFontSize(15);
        doc.setFont("Amiri", "Bold");
        doc.setTextColor("#505050");
        let text = "Scolarité : Etat des impayés - " + levels[level.level];
        doc.text(text, pdf_width / 2, 22, {
          align: "center",
        });

        for (const classroom of level.classrooms) {
          let array = [
            [
              {
                content: "مجموع",
                rowSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 4",
                colSpan: 3,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 3",
                colSpan: 3,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 2",
                colSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 1",
                colSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: classroom.name,
                colSpan: 3,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "التسجيل",
                rowSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
            ],
            [
              cellToDelete,
              { content: "جوان", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "ماي", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "أفريل", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "مارس", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "فيفري", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "جانفي", styles: { fontStyle: "Bold", fontSize: 9 } },
              {
                content: "ديسمبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "نوفمبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "اكتوبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "سبتمبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: levels[level.level],
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: this.schoolarYearName,
                colSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              cellToDelete,
            ],
          ];

          let arr = [];

          let studentId = 1;
          const color = "#fcaa97";
          for (const student of classroom.students) {
            arr = [
              {
                content: this.formatMontant(student.montantTotalMonths) || "",
                styles: { fontSize: 8 },
              },
              {
                content: this.formatMontant(student.months[6]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[6])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[5]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[5])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[4]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[4])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[3]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[3])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[2]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[2])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[1]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[1])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[12]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[12])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[11]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[11])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[10]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[10])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[9]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[9])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: student.monthly,
                styles: {
                  fillColor: student.reduction ? "#ffed91" : "#ffffff",
                },
              },
              { content: student.name },
              { content: studentId++ },
              {
                content: Number(student.inscription) || "",
              },
            ];
            array.push(arr);
          }

          array.push(
            [
              {
                content: this.formatMontant(classroom.montantTotalMonths) || "",
                styles: { fontStyle: "Bold", fontSize: 10 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[6]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[5]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[4]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[3]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[2]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[1]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[12]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[11]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[10]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(classroom.monthsTotal[9]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: "المجموع",
                colSpan: 3,
                rowSpan: 3,
                styles: { fontSize: 11, lineWidth: 0.1 },
              },
              {
                content:
                  this.formatMontant(classroom.montantTotalInscription) ||
                  "0.000",
                styles: { fontStyle: "Bold", fontSize: 10 },
              },
            ],
            [
              { content: "", rowSpan: 2 },
              {
                content:
                  this.formatMontant(classroom.montantTotalMonths) || "0.000",
                colSpan: 10,
                styles: { fontSize: 10, fontStyle: "Bold" },
              },
              cellToDelete,
              { content: "", rowSpan: 2 },
            ],
            [
              cellToDelete,
              { content: "جوان" },
              { content: "ماي" },
              { content: "أفريل" },
              { content: "مارس" },
              { content: "فيفري" },
              { content: "جانفي" },
              { content: "ديسمبر" },
              { content: "نوفمبر" },
              { content: "اكتوبر" },
              { content: "سبتمبر" },
              cellToDelete,
              cellToDelete,
            ]
          );

          // fix table
          for (let row = 0; row < array.length; row++) {
            array[row] = array[row].filter((cell) => cell !== cellToDelete);
          }

          doc.autoTable({
            theme: "grid",
            styles: {
              font: "Amiri",
              halign: "right",
              fontSize: 9,
              halign: "center",
              valign: "middle",
              fontStyle: "normal",
              lineColor: "#363636",
              lineWidth: 0.1,
              cellPadding: {
                right: 0,
                left: 0,
                top: 1,
                bottom: 1,
              },
            },
            pageBreak: "avoid",
            body: array,
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 15 },
              2: { cellWidth: 15 },
              3: { cellWidth: 15 },
              4: { cellWidth: 15 },
              5: { cellWidth: 15 },
              6: { cellWidth: 15 },
              7: { cellWidth: 15 },
              8: { cellWidth: 15 },
              9: { cellWidth: 15 },
              10: { cellWidth: 15 },
              11: { cellWidth: 15 },
              12: { cellWidth: 50 },
              13: { cellWidth: 9 },
              14: { cellWidth: 20 },
            },
            margin: { top: 35 },
            didDrawPage: function (data) {
              // Reseting top margin. The change will be reflected only after print the first page.
              data.settings.margin.top = 10;
            },
          });
        }

        doc.addPage();
      }

      const array = [
        [
          { content: "Scolarité", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "جوان", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "ماي", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "أفريل", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "مارس", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "فيفري", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "جانفي", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "ديسمبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "نوفمبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "اكتوبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "سبتمبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "المستويات", styles: { fontStyle: "Bold", fontSize: 10 } },
          {
            content: "Inscription",
            styles: { fontStyle: "Bold", fontSize: 10 },
          },
        ],
      ];

      try {
        doc.addImage(imgData, "JPEG", 10, 8, 20, 20);
      } catch (error) {
        console.log(error);
      }

      doc.setFont("Amiri", "normal");
      doc.setFontSize(10);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.text(
        new Date().toLocaleDateString("fr-FR", options),
        pdf_width - 17,
        8,
        {
          align: "right",
        }
      );

      doc.setFontSize(15);
      doc.setFont("Amiri", "Bold");
      doc.setTextColor("#505050");
      let text = "Scolarité : Etat des impayés - جميع المستويات";
      doc.text(text, pdf_width / 2, 22, {
        align: "center",
      });

      for (const level of impayeData.levels) {
        let arr = [
          {
            content: this.formatMontant(level.montantTotalMonths) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[6]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[5]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[4]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[3]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[2]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[1]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[12]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[11]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[10]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[9]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: levels[level.level],
            styles: { fontSize: 9, fontStyle: "Bold" },
          },
          {
            content: this.formatMontant(level.montantTotalInscription) || "",
            styles: { fontSize: 8 },
          },
        ];
        array.push(arr);
      }

      array.push(
        [
          {
            content: this.formatMontant(impayeData.montantTotalMonths) || "",
            styles: { fontStyle: "Bold", fontSize: 10 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[6]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[5]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[4]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[3]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[2]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[1]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[12]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[11]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[10]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[9]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: "المجموع",
            rowSpan: 2,
            styles: { fontSize: 11, lineWidth: 0.1 },
          },
          {
            content:
              this.formatMontant(impayeData.montantTotalInscription) || "0.000",
            styles: { fontStyle: "Bold", fontSize: 10 },
          },
        ],
        [
          { content: "" },
          {
            content:
              this.formatMontant(impayeData.montantTotalMonths) || "0.000",
            colSpan: 10,
            styles: { fontSize: 10, fontStyle: "Bold" },
          },
          cellToDelete,
          { content: "" },
        ]
      );

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        theme: "grid",
        styles: {
          font: "Amiri",
          halign: "right",
          fontSize: 9,
          halign: "center",
          valign: "middle",
          fontStyle: "normal",
          lineColor: "#363636",
          lineWidth: 0.1,
          cellPadding: {
            right: 0,
            left: 0,
            top: 1.25,
            bottom: 1.25,
          },
        },
        pageBreak: "avoid",
        body: array,
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 20 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          7: { cellWidth: 20 },
          8: { cellWidth: 20 },
          9: { cellWidth: 20 },
          10: { cellWidth: 20 },
          11: { cellWidth: 20 },
          12: { cellWidth: 20 },
        },
        margin: { top: 35 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      this.pdfLoading = false;
      doc.save("Scolarité : Etat des impayés.pdf");
    },

    async generatePdfActivites() {
      this.pdfLoading = true;

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }

      var impayeData;

      await apiClient
        .get("/comptabilite/etatImpaye/activity")
        .then((res) => (impayeData = res.data))
        .catch((e) => {
          console.error(e);
          this.$message.error(this.$t("error.error"));
        });

      const levels = {
        "-2": "Préscolaire",
        "-1": "تحضيري",
        1: "سنة أولى",
        2: "سنة ثانية",
        3: "سنة ثالثة",
        4: "سنة رابعة",
        5: "سنة خامسة",
        6: "سنة سادسة",
        7: "سنة سابعة",
        8: "سنة ثامنة",
        9: "سنة تاسعة",
        10: "أولى ثانوي",
        11: "ثانية ثانوي",
        12: "ثالثة ثانوي",
        13: "رابعة ثاتوي",
      };

      const doc = new JsPDF({
        orientation: "p",
        unit: "mm",
        format: "a3",
      });

      const pdf_width = doc.internal.pageSize.getWidth();

      const cellToDelete = {};

      for (const level of impayeData.levels) {
        if (level.activites.length === 0) continue;
        try {
          doc.addImage(imgData, "JPEG", 10, 8, 20, 20);
        } catch (error) {
          console.log(error);
        }

        doc.setFont("Amiri", "normal");
        doc.setFontSize(10);

        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };

        doc.text(
          new Date().toLocaleDateString("fr-FR", options),
          pdf_width - 17,
          8,
          {
            align: "right",
          }
        );

        doc.text(this.schoolarYearName, pdf_width / 2, 8, {
          align: "center",
        });

        doc.setFontSize(15);
        doc.setFont("Amiri", "Bold");
        doc.setTextColor("#505050");
        let text = "Activités : Etat des impayés - " + levels[level.level];
        doc.text(text, pdf_width / 2, 22, {
          align: "center",
        });

        for (const activity of level.activites) {
          let array = [
            [
              {
                content: "مجموع",
                rowSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 4",
                colSpan: 3,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 3",
                colSpan: 3,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 2",
                colSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "Tranche القسط 1",
                colSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: activity.name,
                colSpan: 3,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "التسجيل",
                rowSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
            ],
            [
              cellToDelete,
              { content: "جوان", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "ماي", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "أفريل", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "مارس", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "فيفري", styles: { fontStyle: "Bold", fontSize: 9 } },
              { content: "جانفي", styles: { fontStyle: "Bold", fontSize: 9 } },
              {
                content: "ديسمبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "نوفمبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "اكتوبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: "سبتمبر",
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: levels[level.level],
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              {
                content: this.schoolarYearName,
                colSpan: 2,
                styles: { fontStyle: "Bold", fontSize: 9 },
              },
              cellToDelete,
            ],
          ];

          let arr = [];

          let studentId = 1;
          const color = "#fcaa97";
          for (const student of activity.students) {
            arr = [
              {
                content: this.formatMontant(student.montantTotalMonths) || "",
                styles: { fontSize: 8 },
              },
              {
                content: this.formatMontant(student.months[6]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[6])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[5]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[5])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[4]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[4])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[3]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[3])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[2]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[2])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[1]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[1])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[12]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[12])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[11]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[11])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[10]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[10])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: this.formatMontant(student.months[9]) || "",
                styles: {
                  fontSize: 8,
                  fillColor: this.formatMontant(student.months[9])
                    ? color
                    : "#ffffff",
                },
              },
              {
                content: student.monthly,
                styles: {
                  fillColor: student.reduction ? "#ffed91" : "#ffffff",
                },
              },
              { content: student.name },
              { content: studentId++ },
              {
                content: Number(student.inscription) || "",
              },
            ];
            array.push(arr);
          }

          array.push(
            [
              {
                content: this.formatMontant(activity.montantTotalMonths) || "",
                styles: { fontStyle: "Bold", fontSize: 10 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[6]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[5]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[4]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[3]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[2]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[1]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[12]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[11]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[10]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: this.formatMontant(activity.monthsTotal[9]) || "",
                styles: { fontStyle: "Bold", fontSize: 8 },
              },
              {
                content: "المجموع",
                colSpan: 3,
                rowSpan: 3,
                styles: { fontSize: 11, lineWidth: 0.1 },
              },
              {
                content:
                  this.formatMontant(activity.montantTotalInscription) ||
                  "0.000",
                styles: { fontStyle: "Bold", fontSize: 10 },
              },
            ],
            [
              { content: "", rowSpan: 2 },
              {
                content:
                  this.formatMontant(activity.montantTotalMonths) || "0.000",
                colSpan: 10,
                styles: { fontSize: 10, fontStyle: "Bold" },
              },
              cellToDelete,
              { content: "", rowSpan: 2 },
            ],
            [
              cellToDelete,
              { content: "جوان" },
              { content: "ماي" },
              { content: "أفريل" },
              { content: "مارس" },
              { content: "فيفري" },
              { content: "جانفي" },
              { content: "ديسمبر" },
              { content: "نوفمبر" },
              { content: "اكتوبر" },
              { content: "سبتمبر" },
              cellToDelete,
              cellToDelete,
            ]
          );

          // fix table
          for (let row = 0; row < array.length; row++) {
            array[row] = array[row].filter((cell) => cell !== cellToDelete);
          }

          doc.autoTable({
            theme: "grid",
            styles: {
              font: "Amiri",
              halign: "right",
              fontSize: 9,
              halign: "center",
              valign: "middle",
              fontStyle: "normal",
              lineColor: "#363636",
              lineWidth: 0.1,
              cellPadding: {
                right: 0,
                left: 0,
                top: 1,
                bottom: 1,
              },
            },
            pageBreak: "avoid",
            body: array,
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 15 },
              2: { cellWidth: 15 },
              3: { cellWidth: 15 },
              4: { cellWidth: 15 },
              5: { cellWidth: 15 },
              6: { cellWidth: 15 },
              7: { cellWidth: 15 },
              8: { cellWidth: 15 },
              9: { cellWidth: 15 },
              10: { cellWidth: 15 },
              11: { cellWidth: 15 },
              12: { cellWidth: 50 },
              13: { cellWidth: 9 },
              14: { cellWidth: 20 },
            },
            margin: { top: 35 },
            didDrawPage: function (data) {
              // Reseting top margin. The change will be reflected only after print the first page.
              data.settings.margin.top = 10;
            },
          });
        }

        doc.addPage();
      }

      const array = [
        [
          { content: "Activités", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "جوان", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "ماي", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "أفريل", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "مارس", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "فيفري", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "جانفي", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "ديسمبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "نوفمبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "اكتوبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "سبتمبر", styles: { fontStyle: "Bold", fontSize: 10 } },
          { content: "المستويات", styles: { fontStyle: "Bold", fontSize: 10 } },
          {
            content: "Inscription",
            styles: { fontStyle: "Bold", fontSize: 10 },
          },
        ],
      ];

      try {
        doc.addImage(imgData, "JPEG", 10, 8, 20, 20);
      } catch (error) {
        console.log(error);
      }

      doc.setFont("Amiri", "normal");
      doc.setFontSize(10);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.text(
        new Date().toLocaleDateString("fr-FR", options),
        pdf_width - 17,
        8,
        {
          align: "right",
        }
      );

      doc.setFontSize(15);
      doc.setFont("Amiri", "Bold");
      doc.setTextColor("#505050");
      let text = "Activités : Etat des impayés - جميع المستويات";
      doc.text(text, pdf_width / 2, 22, {
        align: "center",
      });

      for (const level of impayeData.levels) {
        let arr = [
          {
            content: this.formatMontant(level.montantTotalMonths) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[6]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[5]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[4]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[3]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[2]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[1]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[12]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[11]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[10]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: this.formatMontant(level.monthsTotal[9]) || "",
            styles: { fontSize: 8 },
          },
          {
            content: levels[level.level],
            styles: { fontSize: 9, fontStyle: "Bold" },
          },
          {
            content: this.formatMontant(level.montantTotalInscription) || "",
            styles: { fontSize: 8 },
          },
        ];
        array.push(arr);
      }

      array.push(
        [
          {
            content: this.formatMontant(impayeData.montantTotalMonths) || "",
            styles: { fontStyle: "Bold", fontSize: 10 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[6]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[5]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[4]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[3]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[2]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[1]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[12]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[11]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[10]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: this.formatMontant(impayeData.monthsTotal[9]) || "",
            styles: { fontStyle: "Bold", fontSize: 8 },
          },
          {
            content: "المجموع",
            rowSpan: 2,
            styles: { fontSize: 11, lineWidth: 0.1 },
          },
          {
            content:
              this.formatMontant(impayeData.montantTotalInscription) || "0.000",
            styles: { fontStyle: "Bold", fontSize: 10 },
          },
        ],
        [
          { content: "" },
          {
            content:
              this.formatMontant(impayeData.montantTotalMonths) || "0.000",
            colSpan: 10,
            styles: { fontSize: 10, fontStyle: "Bold" },
          },
          cellToDelete,
          { content: "" },
        ]
      );

      // fix table
      for (let row = 0; row < array.length; row++) {
        array[row] = array[row].filter((cell) => cell !== cellToDelete);
      }

      doc.autoTable({
        theme: "grid",
        styles: {
          font: "Amiri",
          halign: "right",
          fontSize: 9,
          halign: "center",
          valign: "middle",
          fontStyle: "normal",
          lineColor: "#363636",
          lineWidth: 0.1,
          cellPadding: {
            right: 0,
            left: 0,
            top: 1.25,
            bottom: 1.25,
          },
        },
        pageBreak: "avoid",
        body: array,
        columnStyles: {
          0: { cellWidth: 25 },
          1: { cellWidth: 20 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          7: { cellWidth: 20 },
          8: { cellWidth: 20 },
          9: { cellWidth: 20 },
          10: { cellWidth: 20 },
          11: { cellWidth: 20 },
          12: { cellWidth: 20 },
        },
        margin: { top: 35 },
        didDrawPage: function (data) {
          // Reseting top margin. The change will be reflected only after print the first page.
          data.settings.margin.top = 10;
        },
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      this.pdfLoading = false;
      doc.save("Activités : Etat des impayés.pdf");
    },
    handleChangeSelectMois(value) {
      const rowData = [...this.rowData];
      if (value == "all") {
        this.mF = true;
        this.data = rowData;
      } else {
        this.mF = false;
        this.data = rowData
          .filter(
            (elem) =>
              !elem.once &&
              typeof elem.months === "object" &&
              elem.months !== null
          )
          .map((elem) => {
            const months = { ...elem.months };
            let newMonths = {};
            Object.keys(months).forEach((key) => {
              if (parseInt(key.split("-")[1]) == parseInt(value))
                newMonths[key] = months[key];
            });
            return { ...elem, months: newMonths };
          })
          .filter(
            (elem) =>
              Object.keys(elem.months).length > 0 && this.showThisRow(elem)
          );
      }
      this.filtredTable = this.data;
    },
  },
};
</script>
